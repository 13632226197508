<template>
  <b-card style="margin-top: 5vh !important;">
    <b-row align-h="end">
      <b-col cols="2" class="my-1" align-self="end" style="text-align: end">
        <div
          class="d-flex align-items-center justify-content-end"
          align-self="end"
          style="text-align: end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              class="d-inline-block"
              placeholder="Search"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <b-table
      :busy="isBusy"
      responsive="sm"
      :fields="fields"
      :per-page="perPage"
      :items="opportunities"
      :current-page="currentPage"
      :filter="filter"
      @filtered="onFiltered"
      @row-clicked="clickRow"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(sf_opportunity_id)="data">
        {{ data.item.sf_opportunity_number }}
      </template>
      <template #cell(address)="data">
        {{ data.value + ", " + data.item.geography }}
      </template>
      <template #cell(rentable_sqft)="data">
        {{
          Number(data.value).toLocaleString(undefined, { minimumFractionDigits: 0 }) +
          " sqft"
        }}
      </template>
      <template #cell(updated_at)="data">
        <div :title="formatDatetime(data.item.updated_at)">
          {{ relativeTime(data.item.updated_at) }}
        </div>
      </template>

      <template #cell(sf_opportunity_status)="data">
        <b-badge
          pill
          variant="primary"
          :class="data.value.toLowerCase().split(' ').join('')"
        >
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex">
          <div :id="'tooltip-eye' + data.item.sf_opportunity_number">
            <a
              @click="clicked(data.item)"
              :href="`/active/${data.item.sf_opportunity_id}`"
            >
              <feather-icon icon="EyeIcon" size="16" style="margin-right: 5px" />
              <span class="align-middle" style="margin-right: 15px">View</span>
            </a>
          </div>

          <b-tooltip
            :target="'tooltip-eye' + data.item.sf_opportunity_number"
            title="Preview Complete Project"
          />
        </div>
      </template>
    </b-table>
    <b-row style="float: right">
      <b-col cols="2">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BPagination,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BDropdown,
  BTooltip,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BButton,
    BModal,
    BRow,
    BCol,
    BBadge,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BDropdown,
    BTooltip,
    BDropdownItem,
    moment,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      sortBy: "updated_at",
      sortDesc: true,
      opportunities: [],
      isBusy: true,
      filter: null,
      fields: [
        {
          key: "sf_opportunity_id",
          label: "#",
          sortable: true,
        },
        {
          key: "address",
          label: "PROJECT",
          sortable: false,
        },
        {
          key: "account_name",
          label: "CLIENT",
          sortable: true,
        },
        {
          key: "rentable_sqft",
          label: "RENTABLE SQFT",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "Last updated",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 1,
      statusOptions: [
        "Identified",
        "Quote Sent",
        "WON",
        "Quote Hardened",
        "Quote Revised",
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  beforeMount() {
    this.getCompletedOpportunities();
  },
  methods: {
    getCompletedOpportunities() {
      this.$http
        .get("/opportunities/complete", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.opportunities = response.data;
          this.totalRows = this.opportunities.length;
          this.isBusy = false;

          document.title = `Completed Projects`;
        })
        .catch((error) => {
          this.showToast("danger", error.response.data.message);
        });
    },
    clicked(item) {
      // console.log(item);
      const { sf_opportunity_id } = item;
      this.$router.push({
        path: `/active/${sf_opportunity_id}`,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    relativeTime(date) {
      return this.$dateF.formatDate(date);
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date);
    },
    clickRow(record, index) {
      this.clicked(record);
      // console.log(record);
    },
  },
};
</script>

<style scoped>
.identified {
  background-color: #0d6efd1f;
  color: #0d6efd;
}

.quotesent {
  background-color: #6610f21f;
  color: #6610f2;
}

.quoterevised {
  background-color: #7367f01f;
  color: #7367f0;
}

.quotehardened {
  background-color: #d633841f;
  color: #d63384;
}

.approved {
  background-color: #00cfe81f;
  color: #00cfe8;
}

.won {
  background-color: #28c76f1f;
  color: #28c76f;
}

.lost {
  background-color: #6c757d1f;
  color: #4b4b4b;
}

.onhold {
  background-color: #ff9f431f;
  color: #ff9f43;
}

.invoice-filter-select {
  min-width: 190px;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
